<template>
   <v-dialog
    v-model="dialog"
    fullscreen
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        
        icon
        color="green"
        class="pr-4 ma-2"
      ><v-icon>mdi-image-outline</v-icon></v-btn>
    </template>
    <v-card>{{ currentItem.length }}
      <v-card-text>   
        <v-row>
          <v-col class="text-end">
            <v-btn icon @click="dialog=false"><v-icon>mdi-close-outline</v-icon></v-btn></v-col>
        </v-row>
        <v-carousel 
          v-model="model"
          height="100%"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item 
            v-for="(e, i) in currentItem"
            :key="i"
          >
            <v-sheet

              tile
              class="ma-auto pb-12"
            > 
            <v-card :loading="true">
              
              {{ e.url }}
              <v-img
                class="ma-auto"
                max-height="56%"
                max-width="56%" 
                :lazy-src="`${e.url}`" 
                :src="`${e.url}`" 
                :alt="`${e.url}`"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
            </v-sheet>
          </v-carousel-item>
        </v-carousel> 
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name:"Galeria",
  components:{
  },
  props:{
    currentItem:{
      type:Array
    },
    currentId:{
      type:Number
    },
    currentArquivo:{
      type:Object
    }
  },
  data:()=>({
    dialog:false,
    model:0,
    cont:0,
  }),
  methods:{
    contador(val){
      console.log(val)
      return val += val
    }
  },
  computed:{
    loading_images(){
      return this.currentItem.length>=1
    }
    // lista(){
    //   let arr = this.currentItem.map(i=>{
    //     // para evitar de carregar arquivos de pdf
    //     if(i !== undefined){
    //       if(i.arquivo.split('.').reverse()[0]!=='pdf'){
    //         return i
    //       }
    //     }
    //   })
    //   return arr.filter(Boolean)
    // }
  }
}
</script>